@import "~antd/dist/antd.less";

.background {
  width: "100%";
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(255, 242, 236, 0.8) 0%,
    rgba(244, 242, 255, 0.8) 50%,
    rgba(236, 251, 255, 0.8) 100%
  );
  mix-blend-mode: normal;
  backdrop-filter: blur(40px);
}

.Layout {
  min-height: 100vh;
  background-color: white;
}

.sider {
  position: sticky;
  top: 0px;
  height: 100vh;
  border-right-width: 1px;
  border-right-color: #e6eef0;
}

.Sidebar-menu {
  padding-left: 50px;
}

.Sidebar-item-title {
  font-size: 16px;
  line-height: 24px;
  color: #393550;
  font-weight: 600;
}

.ant-table-thead th {
  background: #e6eef0 !important;
  border-top: solid 1px #cdd8ed !important;
  border-bottom: solid 1px #cdd8ed !important;
  height: 56px im !important;
}

.ant-table-tbody > tr > td {
  background-color: #ffffff !important;
}

.ant-table-tbody > tr:hover > td {
  background: #e6eef0 !important;
  opacity: 0.6 !important;
}

.item-group-container {
  background: #e6eef0;
  border-radius: 16px;
  width: 100%;
}

.item-group {
  background: #ffffff;
  border: 1px solid #f8f9fb;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
}

.item-group:hover {
  opacity: 0.9;
}

.order-item-info-box {
  background-color: #f8f9fb;
  width: 100%;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 16px;
}


@media only screen and (max-width: 768px) {

}
@primary-color: #393550;@text-color: #393550;@font-size-base: 14px;