@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AvenirLTStd-Black";
  font-weight: 300;
  src: local("AvenirLTStd-Black.otf"),
    url(./assets/fonts/AvenirLTStd-Black.otf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: "100%";
}
